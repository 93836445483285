<script setup lang="ts">
import { genders, type Noun } from '~/src/classes.ts';

const props = defineProps<{
    noun: Noun;
    base?: Noun;
}>();

const config = useConfig();

const visibleGenders = computed(() => {
    return genders.filter((gender) => {
        return props.noun[gender].length > 0 || props.noun[`${gender}Pl`].length > 0;
    });
});

const numerus = computed(() => {
    if (config.nouns.plurals) {
        return [false, true];
    }
    return [false];
});
</script>

<template>
    <template v-for="gender in visibleGenders" :key="gender">
        <div class="d-md-none bold" :style="{ gridArea: `${gender}Label` }">
            <NounsGenderLabel :gender="gender" concise />
        </div>
        <div
            v-for="plural in numerus"
            :key="plural ? 'plural' : 'singular'"
            :style="{ gridArea: `${gender}${plural ? 'Pl' : ''}` }"
        >
            <NounsItem :noun="noun" :gender="gender" :plural="plural" />

            <small v-if="base">
                <p><strong><T>nouns.edited</T><T>quotation.colon</T></strong></p>
                <Diff switchable>
                    <template #before><NounsItem :noun="base" :gender="gender" :plural="plural" /></template>
                    <template #after><NounsItem :noun="noun" :gender="gender" :plural="plural" /></template>
                </Diff>
            </small>
        </div>
    </template>
    <div v-if="noun.sourcesData.length" style="grid-area: sources">
        <p><strong><T>sources.referenced</T><T>quotation.colon</T></strong></p>
        <ul class="list-unstyled mb-0">
            <li v-for="source in noun.sourcesData" :key="source.id">
                <SourceItem :source="source" />
            </li>
        </ul>
    </div>
    <div style="grid-area: buttons">
        <slot name="buttons"></slot>
    </div>
</template>
